<template>
	<v-card class="mb-2">
		<v-container class="pa-0 ma-0 white--text primary">
			<v-row class="px-4 pb-0 mt-auto">
				<v-col class="subtitle-1">{{ title }}</v-col>
				<v-col class="text-right">Fakturanr: {{ invoice.number }}</v-col>
			</v-row>

			<v-card-text class="pt-0 title white--text">
				Att betala:
				{{ invoice.amount.total | $_invoiceMixin_formatMoney(2) }} kr
				<br />
				<div class="subtitle-1">
					Bankgiro:
					{{ invoice.paymentDestination.bg }}
					<br />
					OCR: {{ invoice.ocr }}
					<br />
					Förfallodatum:
					{{ invoice.date.due }}
				</div>
			</v-card-text>
		</v-container>

		<v-container class="pa-0 ma-0">
			<v-list>
				<template v-if="invoice.paid === 'Y'">
					<v-alert
						type="success"
						outlined
						prominent
						border="left"
						class="mx-3 my-1"
					>
						Faktura betald
					</v-alert>
				</template>
				<template v-else-if="invoice.paid === 'P'">
					<v-alert
						type="info"
						outlined
						prominent
						border="left"
						class="mx-3 my-1"
					>
						Faktura delbetald, kontakta kontoret
					</v-alert>
				</template>
				<template v-else-if="invoice.paid === 'O'">
					<v-alert
						type="info"
						outlined
						prominent
						border="left"
						class="mx-3 my-1"
					>
						Faktura överbetald, kontakta kontoret
					</v-alert>
				</template>

				<template v-else-if="!deprecated">
					<v-alert type="info" prominent border="left" class="mx-3 my-1">
						Denna faktura förfaller om
						{{ daysToDeprecation | positive }} dagar
					</v-alert>
				</template>
				<template v-else>
					<v-alert type="error" prominent border="left" class="mx-3 my-1">
						Denna faktura är ej betald och förföll för
						{{ daysToDeprecation | positive }} dagar sedan
					</v-alert>
				</template>

				<template v-if="invoice.date.reminders.length > 0">
					<v-divider class="mt-3 pt-2" />
					<v-list-item class="pt-2">
						<v-list-item-content class="pt-1">
							<v-list-item-subtitle>Påminnelser</v-list-item-subtitle>
							<v-list-item-title>
								{{ invoice.date.reminders.join(', ') }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>

				<template v-if="invoice.creditedInvoice">
					<v-divider class="mt-3 pt-2" />
					<v-list-item class="pt-2">
						<v-list-item-content class="pt-1">
							<v-list-item-subtitle>Kredit för faktura</v-list-item-subtitle>
							<v-list-item-title>
								{{ invoice.creditedInvoice.number }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-list>

			<v-divider />

			<v-list subheader>
				<v-subheader class="pl-4">Beloppsförklaring</v-subheader>

				<v-list-item v-if="invoice.amount.discount" class="pt-0">
					<v-list-item-content class="pt-0">
						<v-list-item-subtitle>Rabatt</v-list-item-subtitle>
						<v-list-item-title>
							{{ getDiscount }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item class="pt-0">
					<v-list-item-content class="pt-0">
						<v-list-item-subtitle>Netto</v-list-item-subtitle>
						<v-list-item-title>
							{{ invoice.amount.net | $_invoiceMixin_formatMoney }}
							kr
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item class="pt-0">
					<v-list-item-content class="pt-0">
						<v-list-item-subtitle>Moms</v-list-item-subtitle>
						<v-list-item-title>
							{{ invoice.amount.vat | $_invoiceMixin_formatMoney }}
							kr
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item class="pt-0">
					<v-list-item-content class="pt-0">
						<v-list-item-subtitle>Skattereduktion</v-list-item-subtitle>
						<v-list-item-title>{{ getTaxReduction }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item v-if="invoice.amount.pennyMargin" class="pt-0">
					<v-list-item-content class="pt-0">
						<v-list-item-subtitle>Öresavrundning</v-list-item-subtitle>
						<v-list-item-title>
							{{ invoice.amount.pennyMargin | $_invoiceMixin_formatMoney }}
							kr
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item class="pt-0">
					<v-list-item-content class="pt-0">
						<v-list-item-subtitle>Totalt</v-list-item-subtitle>
						<v-list-item-title>
							{{ invoice.amount.total | $_invoiceMixin_formatMoney }}
							kr
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-container>
	</v-card>
</template>

<script>
import spacetime from 'spacetime';
import invoiceMixin from '@/mixins/invoiceMixin';

export default {
	filters: {
		positive(value) {
			return Math.abs(value);
		},
	},
	mixins: [invoiceMixin],
	props: {
		invoice: {
			type: Object,
			required: true,
		},
	},
	computed: {
		paid() {
			return this.invoice.paid === 'Y';
		},
		title() {
			if (this.invoice.creditedInvoice) {
				return 'Kreditfaktura';
			}

			if (this.invoice.credited) {
				return 'Krediterad faktura';
			}

			return 'Faktura';
		},
		daysToDeprecation() {
			// Set now time to 12.00am since date.due time is always 12.00am (does not contain any time)
			return spacetime(this.invoice.date.due, 'Europe/Stockholm').diff(
				spacetime.now('Europe/Stockholm').time('12:00am'),
				'day',
			);
		},
		deprecated() {
			return spacetime
				.now('Europe/Stockholm')
				.isAfter(spacetime(this.invoice.date.due, 'Europe/Stockholm'));
		},
		getTaxReduction() {
			if (!this.invoice.amount.taxReduction) {
				return 'Nej';
			}

			let taxRedux = parseFloat(this.invoice.amount.taxReduction);
			if (taxRedux === 0) {
				return 'Nej';
			}

			// Flip the value from the API
			if (taxRedux > 0) {
				taxRedux *= -1;
			}

			return `${this.$options.filters.$_invoiceMixin_formatMoney(
				taxRedux,
				2,
			)} kr`;
		},
		getDiscount() {
			let discount = parseFloat(this.invoice.amount.discount);

			// Flip the value from the API
			if (discount > 0) {
				discount *= -1;
			}

			return `${this.$options.filters.$_invoiceMixin_formatMoney(
				discount,
				2,
			)} kr`;
		},
	},
};
</script>

<style></style>

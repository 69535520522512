var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"flex-column flex-nowrap align-self-start"},[_c('apollo-query',{attrs:{"query":_vm.query,"update":function (data) { return data.invoice; },"variables":{ id: _vm.id }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var data = ref_result.data;
return [(data)?[_c('a-invoice-card',{attrs:{"invoice":data}}),_c('apollo-query',{attrs:{"query":_vm.fabQuery,"update":function (fabData) {
							var issueTypes = fabData.issueTypes.map(function (issueType) {
								var subj = issueType.subject.split(' - ')[0];
								issueType.subject = subj + " - " + (data.number);
								return issueType;
							});

							return issueTypes;
						},"variables":{ input: { view: 'invoice' } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var nestedData = ref.result.data;
return [(nestedData)?_c('a-create-issue-fab',{attrs:{"issue-types":nestedData}}):_vm._e()]}}],null,true)})]:_c('a-loading',{attrs:{"loading":loading,"type":"card-heading, list-item@3, divider, list-item, list-item-avatar, divider, card-heading, list-item-two-line@4"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
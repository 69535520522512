const invoiceMixin = {
	filters: {
		$_invoiceMixin_formatMoney(value = 0, decimals = 2) {
			const formatted = value
				.toFixed(decimals)
				.replace(/\d(?=(\d{3})+(\.\d+)?$)/g, '$& ');
			return formatted.replace('.', ',');
		},
	},
};

export default invoiceMixin;

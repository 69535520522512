<template>
	<v-container class="flex-column flex-nowrap align-self-start">
		<apollo-query
			:query="query"
			:update="data => data.invoice"
			:variables="{ id }"
		>
			<template v-slot="{ result: { loading, data } }">
				<template v-if="data">
					<a-invoice-card :invoice="data" />

					<apollo-query
						:query="fabQuery"
						:update="
							fabData => {
								const issueTypes = fabData.issueTypes.map(issueType => {
									const subj = issueType.subject.split(' - ')[0];
									issueType.subject = subj + ` - ${data.number}`;
									return issueType;
								});

								return issueTypes;
							}
						"
						:variables="{ input: { view: 'invoice' } }"
					>
						<template v-slot="{ result: { data: nestedData } }">
							<a-create-issue-fab v-if="nestedData" :issue-types="nestedData" />
						</template>
					</apollo-query>
				</template>

				<a-loading
					v-else
					:loading="loading"
					type="card-heading, list-item@3, divider, list-item, list-item-avatar, divider, card-heading, list-item-two-line@4"
				/>
			</template>
		</apollo-query>
	</v-container>
</template>

<script>
import ALoading from '@/components/Loading';
import AInvoiceCard from '@/components/InvoiceCard';
import ACreateIssueFab from '@/components/CreateIssueFab';
import { INVOICE, ISSUE_CREATION_TYPES } from '@/graphql';

export default {
	name: 'Invoice',
	components: {
		ALoading,
		AInvoiceCard,
		ACreateIssueFab,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
	},
	data: () => ({ query: INVOICE, fabQuery: ISSUE_CREATION_TYPES }),
	metaInfo: {
		title: 'Faktura',
	},
};
</script>

<style></style>
